import {Controller} from '@hotwired/stimulus'
import {debounce} from 'debounce'


export default class extends Controller {

    static targets = ['list', 'dropdown', 'dropdownList']

    connect() {
        addEventListener("load", () => {
            let margin = 20

            this.reserveWidth = this.dropdownTarget.offsetWidth + margin
            this.items = new Map()
            const currentUrl = window.location.pathname

            for (const item of this.listTarget.children) {
                this.items.set(item, item.offsetWidth + margin)

                if (item.getAttribute('href') === currentUrl) {
                    item.classList.add('is_active')
                }
            }

            this.adapt()
            const resize = debounce(() => this.adapt(), 100)
            window.addEventListener('resize', resize)
        })
    }

    adapt() {
        const containerWidth = this.element.offsetWidth
        let stopWidth = this.reserveWidth
        let showMore = false

        this.items.forEach((value, key) => {
            if (containerWidth >= stopWidth + value && !showMore) {
                stopWidth += value
                this.listTarget.append(key)
            } else {
                this.dropdownListTarget.append(key)
                showMore = true
            }
        })

        this.dropdownTarget.classList.toggle('d-none', !showMore)

        if (showMore) {
            this.dropdownTarget.style.opacity = 1
        }
    }

}