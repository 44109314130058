import svgSprite from "./images/sprite.svg"


(function (document) {
    function insertIT () {
        document.body.insertAdjacentHTML('afterbegin', svgSprite)
    }
    if (document.body) {
        insertIT()
    } else {
        document.addEventListener('DOMContentLoaded', insertIT)
    }
}(document))