import {Controller} from '@hotwired/stimulus'


export default class extends Controller {

    static targets = ['form', 'step1', 'step2', 'dropdown']

    connect() {
        this.dropdownLoaded = false
        this.initForm()
    }

    initForm() {
        if (!this.hasFormTarget) {
            return
        }

        RequestHandler.Form(this.formTarget, {
            method: 'POST',
            url: reverse('api_store_customer:token'),
            recaptcha: true,
            onSuccess: () => {
                this.step1Target.classList.add('d-none')
                this.step2Target.classList.remove('d-none')
            }
        })
    }

    openDropdown(event) {
        if (this.dropdownLoaded) {
            return
        }

        RequestHandler.Page(this.dropdownTarget, {
            url: reverse('store_customer:login_dropdown'),
            params: {
                modal: 1
            },
            onSuccess: () => {
                this.dropdownLoaded = true
                this.initForm()
            }
        })

    }
}