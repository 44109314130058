import {Controller} from "@hotwired/stimulus";


export default class extends Controller {

    static targets = ['form', 'file']

    connect() {
        this.modal = mk.Modal(this.element)
        this.initForm()
    }

    initForm() {
        RequestHandler.Form(this.formTarget, {
            onSuccess: () => {
                this.modal.hide()
                mk.notify('success', 'Мы отправили письмо на вашу почту.')
            }
        })
    }

    open(event) {
        this.modal.show()
        this.fileTarget.value = event.detail.id
    }

}