import {Controller} from "@hotwired/stimulus";


export default class extends Controller {

    static targets = ['list', 'more']

    connect() {
        this.page = 1
        this.listRequest = RequestHandler.Template(this.listTarget, {
            url: reverse('api_store_customer:orders'),
            template: '#ordersTemplate',
            pagination: false,
            adjacent: 'beforeend',
            params: {
                page: this.page,
            },
            onSuccess: (data) => {
                if (data.paging.pages > data.paging.page) {
                    this.page++
                    this.moreTarget.classList.remove('d-none')
                } else {
                    this.moreTarget.classList.add('d-none')
                }
            },
            onComplete: () => {
                mk.Loader(this.moreTarget).hide()
            }
        })
    }

    loadMore() {
        mk.Loader(this.moreTarget).show()
        this.listRequest.goToPage(this.page)
    }
}