import {Controller} from "@hotwired/stimulus";


export default class extends Controller {

    static targets = ['imageList', 'thumbList', 'check']

    thumbWidth = 70
    thumbMargin = 10

    connect() {
        this.slideIndex = 1
        this.images = this.imageListTarget.children
        if (this.hasThumbListTarget) {
            this.thumbs = this.thumbListTarget.children
        }
        this.showSlide(this.slideIndex)
    }

    show(event) {
        const nodes = Array.prototype.slice.call(event.currentTarget.parentNode.children)
        this.slideIndex = nodes.indexOf(event.currentTarget) + 1
        this.showSlide(this.slideIndex)
    }

    next() {
        this.showSlide(this.slideIndex += 1)
    }

    prev() {
        this.showSlide(this.slideIndex += -1)
    }

    // Private

    showSlide(index) {
        if (index > this.images.length) {
            this.slideIndex = 1
        } else if (index < 1) {
            this.slideIndex = this.images.length
        }

        for (const image of this.images) {
            image.style.display = 'none'
        }

        this.images[this.slideIndex-1].style.display = 'block'

        if (this.hasThumbListTarget) {
            for (const thumb of this.thumbs) {
                thumb.classList.remove('is_active')
            }
            this.thumbs[this.slideIndex-1].classList.add('is_active')
            this.moveThumbRail()
        }

        if (this.hasCheckTarget) {
            this.checkTarget.textContent = this.slideIndex + '/' + this.images.length
        }
    }

    moveThumbRail() {
        const threshold = 2 - this.slideIndex
        const minThumbOffset = this.thumbListTarget.offsetWidth - this.thumbs.length
            * (this.thumbWidth + this.thumbMargin) + this.thumbMargin
        const maxThumbOffset = 0
        let offset = threshold * (this.thumbWidth + this.thumbMargin)

        if (offset < minThumbOffset) {
            offset = minThumbOffset
        }

        if (maxThumbOffset < offset || maxThumbOffset > 0) {
            offset = maxThumbOffset
        }

        this.thumbListTarget.style.left = offset + 'px'
    }

}