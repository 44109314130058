import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = [
        'order',
        'orderAmount',
        'orderDiscount',
        'orderTotal',
        'product',
        'couponCode',
        'quantity',
        'btnAddCoupon',
        'btnRemoveCoupon',
    ]

    connect() {
        this.timeoutDraft = null
        this.applyUserPrice()
        this.createDraft()
        this.initOrderForm()
    }

    // Public

    changeQuantity() {
        clearTimeout(this.timeoutDraft)
        this.timeoutDraft = setTimeout(() => {
            this.createDraft()
        }, 1000)
    }

    quantityAdd() {
        if (Number(this.quantityTarget.value) < Number(this.quantityTarget.max)) {
            this.quantityTarget.value++
            this.changeQuantity()
        }
    }

    quantityRemove() {
        if (Number(this.quantityTarget.value) > Number(this.quantityTarget.min)) {
            this.quantityTarget.value--
            this.changeQuantity()
        }
    }

    addCoupon(event) {
        RequestHandler({
            method: 'POST',
            url: reverse('store_coupon:check'),
            data: {
                coupon_code: this.couponCodeTarget.value,
                product: event.params.product,
            },
            onBefore: () => {
                mk.Loader(this.orderTarget).show()
            },
            onSuccess: () => {
                mk.Field(this.couponCodeTarget).removeError()
                this.createDraft()
                this.couponCodeTarget.readOnly = true
                this.btnAddCouponTarget.classList.add('d-none')
                this.btnRemoveCouponTarget.classList.remove('d-none')
            },
            onError: (error) => {
                if (error && error.status === 400) {
                    mk.Field(this.couponCodeTarget).addError(error.data.coupon_code)
                }
                mk.Loader(this.orderTarget).hide()
            },
        })
    }

    removeCoupon() {
        this.couponCodeTarget.readOnly = false
        this.couponCodeTarget.value = ''
        this.btnAddCouponTarget.classList.remove('d-none')
        this.btnRemoveCouponTarget.classList.add('d-none')
        this.createDraft()
    }

    // Private

    applyUserPrice() {
        let data = RequestHandler.utils.formSerializer(this.element)
        let cart = JSON.parse(localStorage.getItem('molz.cart'))

        if (cart && cart.hasOwnProperty(data.product)) {
            let el = document.createElement('input')
            el.type = 'hidden'
            el.name = 'user_price'
            el.value = cart[data.product].price
            this.element.prepend(el)
        }
    }

    createDraft() {
        mk.Loader(this.productTarget).show()
        mk.Loader(this.orderTarget).show()

        RequestHandler({
            method: 'POST',
            url: reverse('api_store_order:draft'),
            data: RequestHandler.utils.formSerializer(this.element),
            onSuccess: (data) => {
                this.orderAmountTarget.textContent =
                    RequestHandler.utils.formatMoney(data.amount) + ' ₽'
                this.orderTotalTarget.textContent =
                    RequestHandler.utils.formatMoney(data.total_amount) + ' ₽'
                this.orderDiscountTarget.textContent =
                    '- ' + RequestHandler.utils.formatMoney(data.discount_amount) + ' ₽'
            },
            onError: (error) => {
                if (error.status === 400) {
                    mk.notify('error', error.data[Object.keys(error.data)[0]][0])
                    if (error.data['quantity']) {
                        mk.Field('#quantityField').addError([])
                    }
                }
            },
            onComplete: () => {
                mk.Loader(this.productTarget).hide()
                mk.Loader(this.orderTarget).hide()
            },
        })
    }

    initOrderForm() {
        RequestHandler.Form(this.element, {
            redirect: function (data) {
                if (data.payment_url) {
                    return data.payment_url
                }
                return reverse('store_order:thank_you', [data.uid])
            },
        })
    }
}
