import {Controller} from '@hotwired/stimulus'


export default class extends Controller {

    static targets = ['btn', 'btnFixed', 'price', 'form']

    connect() {
        this.observer = new IntersectionObserver(this.toggle, {
			root: null,
			threshold: 1
		})
        this.observer.observe(this.btnTarget)

        this.initCheckoutForm()

        if (this.hasPriceTarget === true) {
            this.addPwywEvent()
        }
    }

    disconnect() {
        this.observer.unobserve(this.btnTarget)
    }

    // Private

    initCheckoutForm() {
        this.formTarget.addEventListener('submit', event => {
            event.preventDefault()
            let data = RequestHandler.utils.formSerializer(event.target)

            if (data.user_price !== undefined) {
                let cart = {}
                cart[data.product] = {
                    price: data.user_price
                }
                localStorage.setItem('molz.cart', JSON.stringify(cart))
            }

            document.location = event.target.action
        })
    }

    addPwywEvent() {
        this.btnFixedTarget.addEventListener('click', (event) => {
            event.preventDefault()
            this.priceTarget.focus()
        })
    }

    toggle = (entries, opts) => {
        if (entries[0].isIntersecting) {
            this.btnFixedTarget.classList.remove('fade')
            setTimeout(() => {
                this.btnFixedTarget.classList.add('d-none')
            }, 200)
        } else {
            this.btnFixedTarget.classList.remove('d-none')
            setTimeout(() => {
                this.btnFixedTarget.classList.add('fade')
            }, 100)
        }
    }

}