import {Controller} from "@hotwired/stimulus";
import {debounce} from "debounce";


export default class extends Controller {

    static targets = ['wrapper', 'list', 'dropdown', 'dropdownList', 'search', 'searchInput']

    margin = 20
    paddingWrapper = 20
    adaptWidth = 768

    connect() {
        this.sticky()
        addEventListener("load", () => {
            this.items = new Map()
            this.reserveWidth = this.getReserveWidth()

            for (const item of this.listTarget.children) {
                this.items.set(item, item.offsetWidth + this.margin)
            }

            this.adapt()
            const resize = debounce(() => this.adapt(), 100)
            window.addEventListener('resize', resize)
        })
    }

    openSearch(event) {
        this.searchTarget.classList.remove('d-none')
        this.wrapperTarget.classList.add('d-none')
        setTimeout(() => {
            this.searchTarget.classList.add('fade')
        },50)
        this.searchInputTarget.focus()
    }

    closeSearch(event) {
        this.searchTarget.classList.remove('fade')
        setTimeout(() => {
            this.searchTarget.classList.add('d-none')
            this.wrapperTarget.classList.remove('d-none')
            this.searchInputTarget.value = ''
        },250)
    }

    // Private

    getReserveWidth() {
        let reserveWidth = this.dropdownTarget.offsetWidth

        for (const item of this.wrapperTarget.children) {
            if (!item.classList.contains('store-nav__list')) {
                reserveWidth += item.offsetWidth + this.margin
            }
        }

        return reserveWidth
    }

    adapt() {
        if (window.innerWidth >= this.adaptWidth) {
            const containerWidth = this.wrapperTarget.offsetWidth - this.paddingWrapper
            let stopWidth = this.reserveWidth
            let showMore = false

            this.items.forEach((value, key) => {
                if (containerWidth >= stopWidth + value && !showMore) {
                    stopWidth += value
                    this.listTarget.append(key)
                } else {
                    this.dropdownListTarget.append(key)
                    showMore = true
                }
            })

            this.dropdownTarget.classList.toggle('d-none', !showMore)

            if (showMore) {
                this.dropdownTarget.style.opacity = 1
            }
        }
    }

    sticky() {
        const observer = new IntersectionObserver(
            ([e]) => e.target.classList.toggle('store-nav--sticky', e.intersectionRatio < 1),
            {threshold: [1]}
        )
        observer.observe(this.element)
    }

}