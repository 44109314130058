import {Controller} from "@hotwired/stimulus"
import copy from 'copy-to-clipboard'


export default class extends Controller {

    static targets = ['timer', 'indicator', 'status']
    static values = {
        timeleft: Number,
        timelife: Number,
        uid: String,
    }

    connect() {
        this.timelife = this.timelifeValue * 60
        this.timeleft = this.timeleftValue
        if (this.timeleft > 0) {
            this.timer()
        } else {
            this.timeOver()
        }
        this.checkPayment()
    }

    copy(event) {
        copy(event.currentTarget.dataset.value)
        mk.notify('success', 'Скопировано')
    }

    // Private

    checkPayment() {
        let interval = setInterval(() => {
            RequestHandler({
                method: 'GET',
                url: reverse('api_store_order:detail', [this.uidValue]),
                onSuccess: (data) => {
                    if (data['payment_status']['value'] === 2) {
                        clearInterval(interval)
                        let url = reverse('store_order:thank_you', [this.uidValue])
                        window.location.replace(url)
                    }
                    if (this.timeleft < 0) {
                        clearInterval(interval)
                    }
                    this.timeleft = data['time_left']
                },
            })
        },30*1000)
    }

    timer() {
        function zeroPad(num) {
            if (num.toString().length === 1) {
                return '0' + num.toString()
            }
            return num
        }

        const countdown = () => {
            let minutes = Math.floor((this.timeleft % (60 * 60)) / 60)
            let seconds = Math.floor(this.timeleft % 60)
            this.timerTargets.forEach(function (timer) {
                timer.innerHTML = zeroPad(minutes) + ':' + zeroPad(seconds)
            })

            if (this.timeleft < 0) {
                clearInterval(timer)
                this.timeOver()
            }
            this.timeleft--

            let percent = 100 + (this.timeleft - this.timelife) / this.timelife * 100
            this.indicatorTarget.style.width = percent + '%'
        }

        countdown()
        let timer = setInterval(() => {
            countdown()
        }, 1000)
    }

    timeOver() {
        this.statusTarget.innerHTML = 'Время оплаты истекло'
        this.timerTargets.forEach(function (timer) {
            timer.innerHTML = '00:00'
        })
        this.indicatorTarget.style.width = '0%'
    }
}