import {Controller} from "@hotwired/stimulus"


export default class extends Controller {

    static values = {
        uid: String,
        status: Number,
    }

    connect() {
        if (this.statusValue === 1) {
            this.checkPayment()
        }
    }

    // Private

    checkPayment() {
        let time = 5000
        let myFunction = () => {
            time = time * 2
            RequestHandler({
                method: 'GET',
                url: reverse('api_store_order:detail', [this.uidValue]),
                onSuccess: (data) => {
                    if (data['payment_status']['value'] !== 1) {
                        document.location.reload()
                    }
                },
            })
            setTimeout(myFunction, time)
        }
        setTimeout(myFunction, time)
    }

}