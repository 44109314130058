import {Controller} from "@hotwired/stimulus";


export default class extends Controller {

    connect() {
        this.secret = window.location.pathname.split('/').slice(-1)[0]
    }

    download(event) {
        const btn = event.currentTarget
        let timeout = 5000

        mk.Loader(btn).show()

        RequestHandler({
            method: 'POST',
            url: reverse('api_store_order:download_file'),
            data: {
                secret: this.secret,
                file_id: event.params.id,
            },
            onSuccess: (data) => {
                window.location.replace(data.url)
            },
            onError: (response) => {
                if (response.data['non_field_errors']) {
                    this.dispatch('reset', {detail: { id: event.params.id }})
                } else {
                    mk.notify('error', 'Не удалось скачать файл.')
                    throw new Error('File download error. Response data: ' + JSON.stringify(response.data))
                }
            },
            onComplete: (status) => {
                if (status === 'error') {
                    mk.Loader(btn).hide()
                } else {
                    setTimeout(() => {
                        mk.Loader(btn).hide()
                    }, timeout)
                }
            }
        })
    }

}