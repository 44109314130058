import {Controller} from '@hotwired/stimulus'
import productSkeletonTemplate from '../templates/product_skeleton.html'


export default class extends Controller {

    static values = {
        category: Number,
        popup: Boolean,
    }

    initialize() {
        // Получаем текущую страницу
        this.currentPage = RequestHandler.utils.getQueryParameters('page') || 1

        window.onpopstate = (event) => {
            if (event.state && event.state.page) {
                this.getList(event.state.page)
            } else {
                this.getList(1)
            }

            // скрыть/показать модальное окно
            if (this.modal) {
                if (event.state && event.state.modal) {
                    this.modal._element.addEventListener('hide.mk.modal', this.closeModalEvent)
                    this.modal.show()
                } else {
                    this.modal._element.removeEventListener('hide.mk.modal', this.closeModalEvent)
                    this.modal.hide()
                }
            }

        }
    }

    getList(page) {
        if (this.currentPage === page) {
            return
        }

        this.currentPage = page
        const params = {
            page: page,
            modal: 1
        }

        const q = RequestHandler.utils.getQueryParameters('q')
        if (q) {
            params.q = q
        }

        if (this.categoryValue) {
            params.category = this.categoryValue
        }

        RequestHandler.Page(this.element, {
            method: 'GET',
            url: reverse('store_product:list'),
            params: params
        })

        this.element.scrollIntoView(true)
    }

    getPage(event) {
        event.preventDefault()
        RequestHandler.utils.setQueryParameters('page', event.params.page, {page: event.params.page})
        this.getList(event.params.page)
    }

    buy(event) {
        if (event.params.pwyw === 'False') {
            event.stopPropagation()
        }
    }

    detail(event) {
        event.preventDefault()

        if (this.popupValue === false) {
            document.location = event.params.url
            return
        }

        if (!this.modal) {
            this.modal = mk.Modal().create({
                size: 'xl'
            })
        }

        this.modal.content = productSkeletonTemplate
        this.modal.show()

        history.pushState({modal: true, page: this.currentPage}, null, event.params.url)
        this.modal._element.addEventListener('hide.mk.modal', this.closeModalEvent)

        if (this._request) {
            this._request.abort()
        }

        this._request = RequestHandler({
            url: event.params.url,
            params: {
                modal: 1
            },
            onSuccess: (data) => {
                this.modal.content = data
                let product = JSON.parse(this.modal.content.querySelector('#product').textContent)
                gtag('event', 'view_item', {
                    currency: 'RUB',
                    value: product.price,
                    items: [{
                        item_id: product.id,
                        item_name: product.name,
                        price: product.price,
                        currency: 'RUB',
                    }],
                })
            }
        })
    }

    // Private

    closeModalEvent = () => {
        history.back()
    }
}